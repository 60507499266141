export const CLEAR_LINK_DETAILS = "CLEAR_LINK_DETAILS";
export const SET_LINK_DETAILS = "SET_LINK_DETAILS";
export const SET_LINK_CODE = "SET_LINK_CODE";
export const SET_LINK_REDIRECT = "SET_LINK_REDIRECT";

export interface ClearLinkDetailsAction {
  type: typeof CLEAR_LINK_DETAILS;
}

export interface SetLinkDetailsAction {
  type: typeof SET_LINK_DETAILS;
  payload: LinkHubState;
}
export interface SetLinkCodeAction {
  type: typeof SET_LINK_CODE;
  payload: string;
}

export interface SetLinkRedirectAction {
  type: typeof SET_LINK_REDIRECT;
  payload: string;
}

export interface LinkHubState {
  code: string;
  redirectUrl: string;
}

export type LinkHubAction =
  | SetLinkDetailsAction
  | ClearLinkDetailsAction
  | SetLinkRedirectAction
  | SetLinkCodeAction;

/**
 * Change a setting value
 * payload.name: name of the setting prop to change
 * payload.value: new value to apply
 */
export function clearLinkHub(): ClearLinkDetailsAction {
  return { type: CLEAR_LINK_DETAILS };
}

export function setLinkDetails(payload: LinkHubState): SetLinkDetailsAction {
  return { type: SET_LINK_DETAILS, payload };
}

export function setLinkCode(payload: string): SetLinkCodeAction {
  return { type: SET_LINK_CODE, payload };
}
export function setLinkRedirect(payload: string): SetLinkRedirectAction {
  return { type: SET_LINK_REDIRECT, payload };
}
