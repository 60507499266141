import Swal, { SweetAlertIcon } from "sweetalert2";

export const alertSwal = async (
  title: string,
  text: string,
  icon: SweetAlertIcon = "info"
) => {
  console.log(title, text, icon);
  return Swal.fire({
    title,
    text,
    icon,
  });
};
