import {
  CLEAR_LINK_DETAILS,
  LinkHubAction,
  LinkHubState,
  SET_LINK_CODE,
  SET_LINK_DETAILS,
  SET_LINK_REDIRECT,
} from "../actions/linkHub.actions";

const initialState: LinkHubState = {
  code: "",
  redirectUrl: "",
};

const linkHubReducer = (
  state = initialState,
  action: LinkHubAction
): LinkHubState => {
  switch (action.type) {
    case CLEAR_LINK_DETAILS:
      return initialState;
    case SET_LINK_CODE:
      return { ...state, code: action.payload };
    case SET_LINK_REDIRECT:
      return { ...state, redirectUrl: action.payload };
    case SET_LINK_DETAILS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default linkHubReducer;
