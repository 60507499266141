import {
  CHANGE_SELECTED_HUB,
  SelectedHubState,
  SET_SELECTED_HUB_INFO,
  SelectedHubAction,
} from "../actions/hub.actions";

const initialState: SelectedHubState = {
  hub: null,
  info: null,
};

const hubsReducer = (
  state = initialState,
  action: SelectedHubAction
): SelectedHubState => {
  switch (action.type) {
    case CHANGE_SELECTED_HUB:
      return {
        ...state,
        hub: action.payload,
      };
    case SET_SELECTED_HUB_INFO:
      return {
        ...state,
        info: action.payload,
      };

    default:
      return state;
  }
};

export default hubsReducer;
