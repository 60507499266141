import { HubInfo, ListHubsReplyItem } from "../../components/Devices/types/hub-info";

export const CHANGE_SELECTED_HUB = "CHANGE_HUB";
export const SET_SELECTED_HUB_INFO = "SET_HUB_INFO";
export interface ChangeSelectedHubAction {
  type: typeof CHANGE_SELECTED_HUB;
  payload: ListHubsReplyItem;
}

export interface SetSelectedHubInfoAction {
  type: typeof SET_SELECTED_HUB_INFO;
  payload: HubInfo;
}

export interface SelectedHubState {
  hub: ListHubsReplyItem | null;
  info: HubInfo | null;
}

export type SelectedHubAction = ChangeSelectedHubAction | SetSelectedHubInfoAction;

/**
 * Change a setting value
 * payload.name: name of the setting prop to change
 * payload.value: new value to apply
 */
export function changeSelectedHub(payload: ListHubsReplyItem): ChangeSelectedHubAction {
  return { type: CHANGE_SELECTED_HUB, payload };
}
export function setSelectedHubInfo(payload: HubInfo): SetSelectedHubInfoAction {
  return { type: SET_SELECTED_HUB_INFO, payload };
}
