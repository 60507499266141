/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.8.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import { useEffect } from "react";
import { Router } from "react-router-dom";

// App Routes
import Routes from "./Routes";

// Vendor dependencies
import "./vendor";
// Application Styles
import "./styles/bootstrap.scss";
import "./styles/app.scss";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addAccessTokenInterceptor,
  addIdTokenInterceptor,
} from "./utils/axios";
import history from "./history";
declare var PUBLIC_URL: string;
declare var __COMMIT_HASH__: string;

console.log(`Site hash ${__COMMIT_HASH__}`);

const App: React.FC = () => {
  // const App:React.FC = () =>{
  // specify base href from env varible 'PUBLIC_URL'
  // use only if application isn't served from the root
  // for development it is forced to root only

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const basename =
    process.env.NODE_ENV === "development" ? "/" : PUBLIC_URL || "/";

  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    addIdTokenInterceptor(getIdTokenClaims);
  }, [getAccessTokenSilently]);
  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
};

export default App;
