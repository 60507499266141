export interface ISidebarMenuItem {
    heading?: string;
    name?: string;
    icon?: string;
    translate?: string;
    path?: string;
    label?: {
        value: string | number;
        color: string;
    };
    submenu?: ISidebarMenu;
}

export interface ISidebarMenu extends Array<ISidebarMenuItem> {}

const Menu: ISidebarMenu = [
    {
        heading: 'Main Navigation',
        // translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Home',
        path: '/welcome',
        icon: 'icon-home',
        // translate: 'sidebar.nav.WELCOME'
    },
	{
        heading: 'Devices',
        // translate: 'sidebar.heading.HEADER'
    },
	{
        name: 'Devices',
        path: '/devices',
        icon: 'icon-grid',
        // translate: 'sidebar.nav.WELCOME'
    },
	{
        name: 'Linked Applications',
        path: '/linkedapplications',
        icon: 'icon-layers',
        // translate: 'sidebar.nav.WELCOME'
    },
	{
        heading: 'More',
        // translate: 'sidebar.heading.HEADER'
    },
	{
        name: 'Help',
        path: '/extra/help',
        icon: 'icon-notebook',
        // translate: 'sidebar.nav.WELCOME'
    },
	{
        heading: 'Account',
        // translate: 'sidebar.heading.HEADER'
    },
	{
        name: 'Logout',
        path: '/logout',
        icon: 'icon-logout',
        // translate: 'sidebar.nav.WELCOME'
    },
    // {
    //     name: 'Components',
    //     icon: 'icon-speedometer',
    //     translate: 'sidebar.nav.COMPONENTS',
    //     label: { value: 4, color: 'info' },
    //     submenu: [
    //         {
    //             name: 'Buttons',
    //             path: 'buttons'
    //         },
    //         {
    //             name: 'Forms',
    //             path: 'form-standard'
    //         },
    //         {
    //             name: 'Tables',
    //             path: 'table-standard'
    //         },
    //         {
    //             name: 'Cards',
    //             path: 'cards'
    //         }
    //     ]
    // }
];

export default Menu;
