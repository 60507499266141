import { withAuthenticationRequired } from "@auth0/auth0-react";
import PageLoader from "./PageLoader";

const PrivateRoute = (props) => {
  return <props.children />;
};

export default withAuthenticationRequired(PrivateRoute, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <PageLoader />,
});
