import { combineReducers } from "redux";

import settingsReducer from "./settings.reducer";
import themesReducer from "./themes.reducers";
import hubsReducer from "./hub.reducer";
import linkHubReducer from "./linkHub.reducer";

export default combineReducers({
  settings: settingsReducer,
  theme: themesReducer,
  selectedHub: hubsReducer,
  linkHub: linkHubReducer
});
