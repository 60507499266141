import "core-js/es6/string";
import "core-js/es6/array";
import "core-js/es6/map";
import "core-js/es6/set";
import "core-js/es6/object";
import "core-js/es6/promise";
import "core-js/es7/object";
import "core-js/es7/array";
import "raf/polyfill";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";

import "./i18n";

import configureStore from "./store/store";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";
import history from "./history";
const store = configureStore();

const onRedirectCallback = (appState) => {
  // eslint-disable-next-line no-restricted-globals
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  audience: config.audience,
  cacheLocation: "localstorage",
  scope: "openid rako:web profile email read:user_metadata offline_access  read:users read:current_user, read:user_idp_tokens update:current_user",
  redirectUri: window.location.origin,
  onRedirectCallback,
};

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
  document.getElementById("app")
);
