import axios from "axios";
import { ListHubsReplyItem } from "../components/Devices/types/hub-info";
import { alertSwal } from "./swal-alert";
import { IdToken } from "@auth0/auth0-react";

//  default client, has interceptor to alert user when axios has error
export const HTTP_HUB_CLIENT = axios.create();
// same as above, apart from doenst display alert. used for polling etc where failures should be hidden
export const HTTP_HUB_CLIENT_NO_ALERTS = axios.create();

export const HTTP_AUTH0_CLIENT = axios.create({
  baseURL: `https://rako.eu.auth0.com`,
});

export const HTTP_MANAGEMENT_PORTAL_CLIENT = axios.create({
  baseURL: `https://rako.eu.auth0.com/api/v2`,
});

export const HTTP_MYRAKO_API_CLIENT = axios.create({
  baseURL: "https://api.rakocontrols.com",
});

// adds access tokens in all api requests
// this interceptor is only added when the auth0 instance is ready and exports the getAccessTokenSilently method
export const addAccessTokenInterceptor = (getAccessTokenSilently: any) => {
  HTTP_HUB_CLIENT.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();
    config!.headers!.Authorization = `Bearer ${token}`;
    return config;
  });

  HTTP_HUB_CLIENT_NO_ALERTS.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();
    config!.headers!.Authorization = `Bearer ${token}`;
    return config;
  });

  HTTP_AUTH0_CLIENT.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();
    config!.headers!.Authorization = `Bearer ${token}`;
    return config;
  });

  HTTP_MYRAKO_API_CLIENT.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();
    config!.headers!.Authorization = `Bearer ${token}`;
    return config;
  });

  HTTP_HUB_CLIENT.interceptors.response.use(
    (res) => res,
    (error) => {
      const message = error.response?.data?.message || error.message;
      alertSwal("Error", message, "error");
      return Promise.reject(error);
    }
  );

  HTTP_MYRAKO_API_CLIENT.interceptors.response.use(
    (res) => res,
    (error) => {
      const message = error.response?.data?.message || error.message;
      alertSwal("Error", message, "error");
      return Promise.reject(error);
    }
  );
};

export const addIdTokenInterceptor = (
  getItTokenClaims: () => Promise<IdToken | undefined>
) => {
	HTTP_MANAGEMENT_PORTAL_CLIENT.interceptors.request.use(async (config) => {
    const token = await getItTokenClaims();
    config!.headers!.Authorization = `Bearer ${token?.__raw}`;
    return config;
  });
};

// when selected hub changes, the base URL needs to change for the two http clients.
export const changeBaseHubHttp = ({ hubRouter, hubId }: ListHubsReplyItem) => {
  HTTP_HUB_CLIENT.defaults.baseURL = `https://${hubRouter}/hub/${hubId}`;
  HTTP_HUB_CLIENT_NO_ALERTS.defaults.baseURL = `https://${hubRouter}/hub/${hubId}`;
};

export default HTTP_HUB_CLIENT;
