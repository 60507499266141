import { Middleware } from "redux";
import { ApplicationState } from "../store";
import {
  CHANGE_SELECTED_HUB,
  SET_SELECTED_HUB_INFO,
} from "../actions/hub.actions";
import { changeBaseHubHttp } from "../../utils/axios";

// // Helpers to change class attribute
// const updateElementClass = (
//   el: HTMLElement | null,
//   stat: boolean,
//   name: string
// ) => el && el.classList[stat ? "add" : "remove"](name);
// const updateBodyClass = (stat: boolean, name: string) =>
//   updateElementClass(document.body, stat, name);

/**
    When a setting value is changed, detect its value and add/remove
    a classname related with that setting from the target element.
    Export this method to talk directly with the middleware
*/
export const updateHub = (state: ApplicationState) => {
  if (state.selectedHub.hub) changeBaseHubHttp(state.selectedHub.hub);
};

export const updateInfo = (state: ApplicationState) => {};

/*
    Hook into setting changes in order to change layout.
*/
const hub: Middleware = (store) => (next) => (action) => {
  let result = next(action);
  if (action.type === CHANGE_SELECTED_HUB) {
    updateHub(store.getState());
  }
  if (action.type === SET_SELECTED_HUB_INFO) {
    updateInfo(store.getState());
  }
  return result;
};

export default hub;
