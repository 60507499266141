import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, RouteProps } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
/* loader component for Suspense*/
import PageLoader from "./components/Common/PageLoader";
import ProtectedRoute from "./components/Common/ProtectedRoute";

import Base from "./components/Layout/Base";
import BasePage from "./components/Layout/BasePage";
// import Route from "./components/Common/Route";
const Login = lazy(() => import("./components/Login/pages/Login"));
const Logout = lazy(() => import("./components/Logout/pages/Logout"));
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) =>
  <Tag {...props} />;

const Welcome = lazy(() => import("./components/Welcome/pages/Welcome"));
const TermsAndConditions = lazy(
  () => import("./components/TermsAndConditions/pages/TermsAndConditions")
);
const PrivacyPolicy = lazy(
  () => import("./components/PrivacyPolicy/pages/PrivacyPolicy")
);
const Help = lazy(() => import("./components/Help/pages/Help"));
const CloudGateway = lazy(
  () => import("./components/CloudGateway/pages/CloudGateway")
);
const LicenseAgreement = lazy(
  () => import("./components/LicenseAgreement/pages/LicenseAgreement")
);
const ThirdPartyLicenses = lazy(
  () => import("./components/ThirdPartyLicenses/pages/ThirdPartyLicenses")
);
const SecurityVulnerability = lazy(
  () => import("./components/SecurityVulnerability/pages/SecurityVulnerability")
);
const LinkedApplications = lazy(
  () => import("./components/LinkedApplications/pages/LinkedApplications")
);
const Alexa = lazy(() => import("./components/Alexa/pages/Alexa"));
const Sonos = lazy(() => import("./components/Sonos/pages/Sonos"));
const Devices = lazy(() => import("./components/Devices/pages/Devices"));
const Device = lazy(() => import("./components/Devices/pages/Device"));
const Rooms = lazy(() => import("./components/Rooms/pages/Rooms"));
const RoomDetail = lazy(() => import("./components/Rooms/pages/RoomDetail"));

const CustomSceneForm = lazy(
  () => import("./components/CustomScenes/pages/CustomSceneForm")
);
const CustomScenes = lazy(
  () => import("./components/CustomScenes/pages/CustomScenes")
);
const Profile = lazy(() => import("./components/Profile/pages/Profile"));
const ProfileDelete = lazy(
  () => import("./components/Profile/pages/ProfileDelete")
);
const RecoveryImage = lazy(
  () => import("./components/RecoveryImage/pages/RecoveryImage")
);
const Linkhub = lazy(() => import("./components/Link/pages/LinkHub"));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages: Array<string> = [
  "/login",
  "/logout",
  "/link-hub",
  /* See full project for reference */
];

const Routes = ({ location }: RouteProps) => {
  const currentKey = location!.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'

  const animationName = "rag-fadeIn";

  if (listofPages.indexOf(location!.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/link-hub" component={Linkhub} />
            {/* See full project for reference */}
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
      <Base>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <Suspense fallback={<PageLoader />}>
              <Switch location={location}>
                <Route
                  path="/profile/delete"
                  exact
                  component={waitFor(ProfileDelete)}
                />
                <Route path="/profile" exact>
                  <ProtectedRoute>{waitFor(Profile)}</ProtectedRoute>
                </Route>
                <Route path="/devices/detail">
                  <ProtectedRoute>{waitFor(Device)}</ProtectedRoute>
                </Route>

                <Route path="/devices/rooms/:id">
                  <ProtectedRoute>{waitFor(RoomDetail)}</ProtectedRoute>
                </Route>

                <Route path="/devices/rooms">
                  <ProtectedRoute>{waitFor(Rooms)}</ProtectedRoute>
                </Route>

                <Route path="/devices">
                  <ProtectedRoute>{waitFor(Devices)}</ProtectedRoute>
                </Route>

                <Route path="/customscenes/:id">
                  <ProtectedRoute>{waitFor(CustomSceneForm)}</ProtectedRoute>
                </Route>

                <Route path="/customscenes">
                  <ProtectedRoute>{waitFor(CustomScenes)}</ProtectedRoute>
                </Route>

                <Route path="/alexaadvanced" component={waitFor(Alexa)} />
                <Route path="/extra/audio" component={waitFor(Sonos)} />
                <Route
                  path="/extra/privacypolicy"
                  component={waitFor(PrivacyPolicy)}
                />
                <Route
                  path="/extra/termsandcondtions"
                  component={waitFor(TermsAndConditions)}
                />
                <Route path="/extra/help" component={waitFor(Help)} />
                <Route
                  path="/extra/cloudgateway"
                  component={waitFor(CloudGateway)}
                />
                <Route
                  path="/extra/license"
                  component={waitFor(LicenseAgreement)}
                />
                <Route
                  path="/extra/thirdparty"
                  component={waitFor(ThirdPartyLicenses)}
                />
                <Route
                  path="/extra/security-vulnerability"
                  component={waitFor(SecurityVulnerability)}
                />
                <Route
                  path="/linkedapplications"
                  component={waitFor(LinkedApplications)}
                />

                <Route path="/recovery" component={waitFor(RecoveryImage)} />
                <Route path="/welcome" component={waitFor(Welcome)} />
                <Route path="/" component={waitFor(Welcome)} />
              </Switch>
            </Suspense>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
  }
};

export default withRouter(Routes);
