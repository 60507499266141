import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";

const SidebarUserBlock: React.FC = (props) => {
  const { user } = useAuth0();
  const history = useHistory();

  const handleClick = () => {
    history.push("/profile");
  };

  return (
    <React.Fragment>
      {user && (
        <div className="item user-block" onClick={() => handleClick()}>
          {/* User picture */}
          <div className="user-block-picture">
            <div className="user-block-status">
              <img
                className="img-thumbnail rounded-circle"
                src={user.picture}
                alt="Avatar"
                width="60"
                height="60"
              />
              <div className="circle bg-success circle-lg"></div>
            </div>
          </div>
          <div className="user-block-info pt-1">
            <span className="user-block-name">{user.nickname}</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
// }

export default SidebarUserBlock;
